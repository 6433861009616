export function CountDown({ time }) {
    const daysElement = document.getElementById("days");
    const hoursElement = document.getElementById("hours");
    const minutesElement = document.getElementById("minutes");
    const secondsElement = document.getElementById("seconds");

    const daysTextElement = document.getElementById("days-text");
    const hoursTextElement = document.getElementById("hours-text");
    const minutesTextElement = document.getElementById("minutes-text");
    const secondsTextElement = document.getElementById("seconds-text");

    const interval = setInterval(() => {
        const now = new Date().getTime();
        const distance = time - now;

        if (distance < 0) {
            clearInterval(interval);
            daysElement.innerHTML = "0";
            hoursElement.innerHTML = "0";
            minutesElement.innerHTML = "0";
            secondsElement.innerHTML = "0";
            return;
        }

        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        // Skloňování:
        // 0 minut, 1 minuta, 2 minuty, 5 minut
        const daysText = days === 0 ? "dní" : days === 1 ? "den" : days < 5 ? "dny" : "dní";
        const hoursText = hours === 0 ? "hodin" : hours === 1 ? "hodina" : hours < 5 ? "hodiny" : "hodin";
        const minutesText = minutes === 0 ? "minut" : minutes === 1 ? "minuta" : minutes < 5 ? "minuty" : "minut";
        const secondsText = seconds === 0 ? "sekund" : seconds === 1 ? "sekunda" : seconds < 5 ? "sekundy" : "sekund";

        daysElement.innerHTML = days;
        hoursElement.innerHTML = hours;
        minutesElement.innerHTML = minutes;
        secondsElement.innerHTML = seconds;

        daysTextElement.innerHTML = daysText;
        hoursTextElement.innerHTML = hoursText;
        minutesTextElement.innerHTML = minutesText;
        secondsTextElement.innerHTML = secondsText;
    }, 1000);
}

export default CountDown;
