import lightbox from "fslightbox";

export function Lightbox() {
    const lightbox = document.querySelector("[data-fslightbox]");
    if (lightbox) {
        lightbox.addEventListener("click", function (event) {
            event.preventDefault();
            const source = this.getAttribute("href");
            const type = this.getAttribute("data-type");
        });
    }
}

export default Lightbox;
