export function Program() {
    fetch("/dist/data/program.json")
        .then((response) => response.json())
        .then((data) => {
            const eventContainer = document.querySelector(".event-container");
            data.forEach((event) => {
                if (event.title && event.location) {
                    // Ignore empty titles and locations
                    const eventHTML = generateEventHTML(event);
                    eventContainer.insertAdjacentHTML("beforeend", eventHTML);
                }
            });

            setInterval(() => {
                const events = document.querySelectorAll(".event");
                events.forEach((eventElement) => {
                    const progressBar = eventElement.querySelector(".progress");
                    const eventIndex = Array.prototype.indexOf.call(events, eventElement);
                    const event = data[eventIndex];
                    updateProgress(event, eventElement, progressBar);
                });
            }, 1000);
        })
        .catch((error) => {
            console.error("Error fetching events:", error);
        });

    function generateEventHTML(event) {
        return `
        <div class="event">
            <div class="event-title">${event.title}</div>
            <div class="event-location">
                <span class="icon">
                    <svg width="13" height="17" viewBox="0 0 13 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.2557 10.6433L6.01462 16.0313L1.77359 10.6433C1.01478 9.85198 0.497648 8.92274 0.222189 7.85555C-0.0532703 6.78837 -0.0532703 5.72118 0.222189 4.65399C0.497648 3.58681 1.01738 2.65497 1.78139 1.85848C2.5454 1.062 3.44194 0.523197 4.47101 0.242085C5.50009 -0.0390282 6.52916 -0.0390282 7.55823 0.242085C8.58731 0.523197 9.48385 1.062 10.2479 1.85848C11.0119 2.65497 11.5316 3.58681 11.8071 4.65399C12.0825 5.72118 12.0825 6.78837 11.8071 7.85555C11.5316 8.92274 11.0145 9.85198 10.2557 10.6433ZM6.02242 2.0537C4.91539 2.0537 3.97207 2.44413 3.19247 3.225C2.41287 4.00587 2.02307 4.94812 2.02307 6.05175C2.02307 7.15538 2.41287 8.09502 3.19247 8.87068C3.97207 9.64635 4.91539 10.0342 6.02242 10.0342C7.12945 10.0342 8.07277 9.64635 8.85237 8.87068C9.63197 8.09502 10.0218 7.15538 10.0218 6.05175C10.0218 4.94812 9.63197 4.00587 8.85237 3.225C8.07277 2.44413 7.12945 2.0537 6.02242 2.0537Z" fill="#EF4F22"/>
                    </svg>
                </span>
                ${event.location}
            </div>
            <div class="event-time">
                <span class="icon">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C3.5816 16 0 12.4184 0 8C0 3.5816 3.5816 0 8 0C12.4184 0 16 3.5816 16 8C16 12.4184 12.4184 16 8 16ZM14.4 8C14.4 6.30261 13.7257 4.67475 12.5255 3.47452C11.3253 2.27428 9.69739 1.6 8 1.6C6.30261 1.6 4.67475 2.27428 3.47452 3.47452C2.27428 4.67475 1.6 6.30261 1.6 8C1.6 9.69739 2.27428 11.3253 3.47452 12.5255C4.67475 13.7257 6.30261 14.4 8 14.4C9.69739 14.4 11.3253 13.7257 12.5255 12.5255C13.7257 11.3253 14.4 9.69739 14.4 8ZM11.2 7.2C11.4122 7.2 11.6157 7.28429 11.7657 7.43431C11.9157 7.58434 12 7.78783 12 8C12 8.21217 11.9157 8.41566 11.7657 8.56569C11.6157 8.71571 11.4122 8.8 11.2 8.8H8.8C7.92 8.8 7.2 8.08 7.2 7.2V4C7.2 3.78783 7.28429 3.58434 7.43431 3.43431C7.58434 3.28429 7.78783 3.2 8 3.2C8.21217 3.2 8.41566 3.28429 8.56569 3.43431C8.71571 3.58434 8.8 3.78783 8.8 4V7.2H11.2Z" fill="#EF4F22"/>
                    </svg>
                </span>
                ${new Date(event.timeStart).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })} - ${new Date(event.timeEnd).toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" })}
            </div>

            <div class="progress-bar">
                <div class="progress"></div>
            </div>
        </div>
        `;
    }

    function updateProgress(event, eventElement, progressBar) {
        const now = new Date();
        const startTime = new Date(event.timeStart);
        const endTime = new Date(event.timeEnd);

        if (now >= startTime && now <= endTime) {
            eventElement.classList.add("active");
            eventElement.classList.remove("finished");

            const totalDuration = endTime - startTime;
            const elapsedDuration = now - startTime;
            const progressPercentage = (elapsedDuration / totalDuration) * 100;

            progressBar.style.width = `${progressPercentage}%`;
        } else if (now > endTime) {
            eventElement.classList.remove("active");
            eventElement.classList.add("finished");
            progressBar.style.width = "100%";
        } else {
            eventElement.classList.remove("active");
            eventElement.classList.remove("finished");
            progressBar.style.width = "0%";
        }
    }
}

export default Program;
